<template>
  <div>
    <div class="cll">
      <Transition
        :duration="{ enter: 900 }"
        enter-active-class="animate__animated animate__fadeInLeft"
      >
        <div
          v-show="this.currentPage == 'fp-viewing-1-0' ? true : false"
          class="aside_1"
          style="display: flex; justify-content: center; align-items: center"
        >
          <div class="center_left" style="overflow: hidden">
            <div
              class="h1"
              style="
                white-space: nowrap;
                font-weight: 600;
                font-size: 40px;
                margin-bottom: 2px;
                margin-top: 10px;
              "
            >
              公司简介
            </div>
            <div class="h1" style="white-space: nowrap; font-size: 26px">
              Company Profile
            </div>

            <hr style="width: 10%; margin-top: 20px" />
            <div
              class="content"
              style="text-indent: 2em; overflow: hidden; width: 95%"
            >
              <span style=""
                >河南埃科森道路新技术有限公司（简称埃科森）位于郑州智慧岛创智天地，成立于2004年，集产、投、研、用为一体，长期专注于公路基建和交安机电新技术的研发和应用，主要包含绿色公路新材料的研究与应用，智慧交通集成服务以及融合5G通讯、云计算、数字赋能、车路协同的智慧交通云控平台、多场景应用软件的研究与开发，业务涵盖全国20多省市。</span
              >
            </div>
            <div class="content bodr" style="margin-top: 70px" @click="gsgk(1)">
              查看更多
            </div>
          </div>
        </div>
      </Transition>
      <div class="aside_2">
        <Transition
          :duration="{ enter: 1500 }"
          enter-active-class="animate__animated animate__pulse"
        >
          <div
            class="flex"
            v-show="this.currentPage == 'fp-viewing-1-0' ? true : false"
            style="width: 80%; height: 421px"
            @click="gsgk(2)"
          >
            <div
              class="flex"
              style="flex: 5; border: 1px solid white; flex-direction: column"
            >
              <div class="flex yellow" style="flex: 5" @click="gsgk(2)">
                <div class="flex_center" style="flex: 4; height: 209px">
                  <img src="../assets/img/11.png" alt="" srcset="" />
                </div>
                <div
                  class="flex"
                  style="
                    flex: 6;
                    height: 209px;
                    flex-direction: column;
                    color: white;
                  "
                >
                  <div
                    class="flex"
                    style="
                      flex: 5;
                      align-items: flex-end;
                      font-size: 26px;
                      white-space: nowrap;
                    "
                  >
                    公司概况
                  </div>
                  <br />
                  <div style="flex: 5; font-size: 20px; white-space: nowrap">
                    Company Overview
                  </div>
                </div>
              </div>
              <div
                style="flex: 5; border-top: 1px solid white"
                @click="qyzz(2)"
              >
                <div class="flex yellow" style="flex: 5">
                  <div class="flex_center" style="flex: 4; height: 209px">
                    <img src="../assets/img/333.png" alt="" srcset="" />
                  </div>
                  <div
                    class="flex"
                    style="
                      flex: 6;
                      height: 209px;
                      flex-direction: column;
                      color: white;
                    "
                  >
                    <div
                      class="flex"
                      style="
                        flex: 5;
                        align-items: flex-end;
                        font-size: 26px;
                        white-space: nowrap;
                      "
                    >
                      企业资质
                    </div>
                    <br />
                    <div style="flex: 5; font-size: 20px; white-space: nowrap">
                      Company Overview
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="flex"
              style="flex: 5; border: 1px solid white; flex-direction: column"
            >
              <div style="flex: 5" @click="qywh(2)">
                <div class="flex yellow" style="flex: 5">
                  <div class="flex_center" style="flex: 4; height: 209px">
                    <img src="../assets/img/222.png" alt="" srcset="" />
                  </div>
                  <div
                    class="flex"
                    style="
                      flex: 6;
                      height: 209px;
                      flex-direction: column;
                      color: white;
                    "
                  >
                    <div
                      class="flex"
                      style="
                        flex: 5;
                        align-items: flex-end;
                        font-size: 26px;
                        white-space: nowrap;
                      "
                    >
                      企业文化
                    </div>
                    <br />
                    <div style="flex: 5; font-size: 20px; white-space: nowrap">
                      corporate culture
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="yellow"
                style="
                  flex: 5;
                  border-top: 1px solid white;
                  color: aliceblue;
                  font-size: 26px;
                  display: flex;
                  justify-content: space-evenly;
                  align-items: center;
                "
                @click="gsgk(2)"
              >
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  更多详情
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-around;
                    margin-top: 50px;
                  "
                >
                  <img
                    style="width: 60px"
                    src="../assets/img/right9.png"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    qywh(e) {
      this.$router.push({ path: "/about", query: { id: e } });
    },
    qyzz(e) {
      this.$router.push({ path: "/about", query: { id: e } });
    },
    gsgk(e) {
      this.$router.push({ path: "/about", query: { id: e } });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.bto {
  height: 150px;
  opacity: 0.7;
  color: white;
  background: linear-gradient(
    90deg,
    rgba(1, 149, 153, 0.9) 0%,
    rgba(36, 117, 170, 0.9) 100%
  );
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.cll {
  width: 100%;
  height: 100vh;
  background: url(../assets/img/8.png) no-repeat center center;
  background-size: cover;
  display: flex;
}

.aside_1 {
  width: 50%;
  height: 100vh;
  background: linear-gradient(180deg, rgba(0, 150, 151, 0.94) 0%, #2476a8 100%)
    no-repeat center center;
  background-size: cover;
  opacity: 0.9;
}

.aside_2 {
  width: 50%;
  height: 100vh;
  /* background-color: aqua; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.center_left {
  width: 80%;
  height: 600px;
  /* border: 2px solid black; */
  color: white;
}

.content {
  margin-top: 60px;
  width: 100%;
  font-size: 20px;
  font-weight: 200;
  line-height: 50px;
}

.bodr {
  border: 1px solid white;
  width: 130px;
  line-height: 40px;
  display: flex;
  justify-content: center;
}

.bodr:hover {
  cursor: pointer;
}

.h1 {
  font-size: 32px;
  font-weight: 500;
}

.v-enter-active {
  transition: all 5s ease;
}

.flex {
  display: flex;
}

.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.yellow:hover {
  background-color: #ffbf60;
  cursor: pointer;
}
</style>