<template>
  <div id="box">
    <div>
      <div style="
          flex: 3;
          height: 30vh;
          display: flex;
          justify-content: center;
          align-items: center;
        ">
        <div style="
            width: 90%;
            height: 80%;
            margin-top: 140px;
            display: flex;
            flex-direction: column;
            justify-content: center;
          ">
          <p style="font-size: 40px; font-weight: 700; color: #3e3e3e">
            合作单位
          </p>
          <p style="
              font-size: 26px;
              font-weight: 500;
              color: #818181;
              margin-top: 2px;
            ">
            Cooperative Unit
          </p>
          <hr width="60px" color="black" style="margin-top: 20px; font-weight: 100" />
        </div>
      </div>
      <div style="
          flex: 3;
          height: 30vh;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 40px;
        ">
        <div style="
            width: 90%;
            display: flex;
            flex-direction: column;
            justify-content: center;
          ">
          <div>
            <p style="
                font-size: 28px;
                font-weight: 700;
                color: #2375a7;
                margin-top: 20px;
              ">
              开放、合作、共赢
            </p>
            <p style="
                font-size: 20px;
                font-weight: 400;
                color: #505050;
                margin-top: 10px;
              ">
              秉承“开放合作共赢”的宗旨开展云计算、5G应用、智能制造、工业互联网、大数据等新兴科技研发及成果转化，为各类创新人才团队和企业项目落地提供优质的土壤和环境。
            </p>
          </div>
          <div style="margin-top: 70px">
            <p style="
                font-size: 28px;
                font-weight: 700;
                color: #2375a7;
                margin-top: -10px;
              ">
              愿景与使命
            </p>
            <p style="
                font-size: 20px;
                font-weight: 400;
                color: #505050;
                margin-top: 10px;
              ">
              埃科森致力于把数字世界带入每辆车、每条路、每个城市，构建车路互联的智慧交通。
            </p>
            <p style="
                font-size: 20px;
                font-weight: 400;
                color: #505050;
                margin-top: 10px;
              ">
              深入贯彻“交通强国”战略，紧跟“新基建”建设机遇，打造河南智慧交通行业龙头企业、全国领先的智慧交通集成及研发服务商。
            </p>
          </div>
        </div>
      </div>
      <div style="flex: 4; height: 40vh; margin-top: 60px">
        <!-- <div class="swiper-container1 swiper-no-swiping">
          <div class="swiper-wrapper" ref="swiper">
            <div class="swiper-slide">
              <img class="aa" src="../assets/img/90.png" alt="" srcset="" />
            </div>
            <div class="swiper-slide">
              <img class="a1" src="../assets/img/91.png" alt="" srcset="" />
            </div>
            <div class="swiper-slide">
              <img class="a2" src="../assets/img/92.png" alt="" srcset="" />
            </div>
            <div class="swiper-slide">
              <img class="a3" src="../assets/img/93.png" alt="" srcset="" />
            </div>
            <div class="swiper-slide">
              <img class="a4" src="../assets/img/94.png" alt="" srcset="" />
            </div>
          </div>
        </div> -->
        <keep-alive>
          <CootRo></CootRo>
        </keep-alive>
        
      </div>
    </div>
  </div>
</template>

<script>
import CootRo from "../components/CootRo.vue";
import Swiper from "swiper";
import 'swiper/css/swiper.min.css'
export default {
  nema: "CooperaTive",
  components: {
    CootRo,
  },
  data() {
    return {

    };
  },
  mounted() {

  },
  activated() {
    // var swiper = new Swiper(".swiper-container1", {
    //   direction: "horizontal", // 垂直切换选项
    //   loop: true, // 循环模式选项
    //   loopPreventsSlide: true, // 当swiper 正在过渡时，阻止slide 前进后退的切换操作
    //   observer: true,
    //   observeParents: true,
    //   observeSlideChildren: true,
    //   slidesPerView: 5, // swiper容器同时展示的元素数量
    //   // slidesPerGroup:5,
    //   spaceBetween: 0, // 元素之间间隔距离
    //   speed: 1000, // slider自动滑动开始到结束的时间
    //   autoplay: {
    //     // 开启自动切换
    //     delay: 0, // 自动切换的时间间隔
    //     stopOnLastSlide: false, // 当切换到最后一个slide时停止自动切换
    //     disableOnInteraction: false, // 用户操作swiper之后，是否停止自动切换效果
    //   },
    // });
  },
  updated() {

  },
};
</script>

<style lang="scss" scoped>
#box {
  width: 100%;
  max-width: 1920px !important;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: url(../assets/img/95.png) no-repeat center center;
  background-size: cover;
}

// .swiper-container1 {
//   width: 90%;
//   height: 200px;
//   overflow: hidden;
//   display: flex;
//   margin-left: 5%;
//   min-width: 1400px;
// }

// .swiper-wrapper {
//   padding: 10px;
//   box-sizing: border-box;
// }

// .swiper-slide {
//   border-radius: 20px;
//   margin-bottom: 24px;
// }

// img {
//   width: 80%;
// }

// .swiper-wrapper {
//   transition-timing-function: linear !important;
//   -webkit-transition-timing-function: linear !important;
//   -moz-transition-timing-function: linear !important;
//   -ms-transition-timing-function: linear !important;
//   -o-transition-timing-function: linear !important;
// }

// .a1:hover {
//   scale: 1.2;
// }

// .a2:hover {
//   scale: 1.2;
// }

// .a3:hover {
//   scale: 1.2;
// }

// .a4:hover {
//   scale: 1.2;
// }

// .aa:hover {
//   scale: 1.2;
// }
</style>