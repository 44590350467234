<template>
  <div id="box">
    <div style="">
      <div
        style="
          width: 60vw;
          height: 100vh;
          background-color: #3e3e3e;
          position: absolute;
          right: 0;
          margin-top: 80px;
        "
      >
        <!-- 地图容器 -->
        <div id="container"></div>
      </div>
      <div
        style="
          flex: 3;
          height: 30vh;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div
          style="
            width: 90%;
            height: 80%;
            margin-top: 150px;
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
        >
          <p style="font-size: 2vw; font-weight: 700; color: #3e3e3e">
            联系我们
          </p>
          <p
            style="
              font-size: 26px;
              font-weight: 500;
              color: #818181;
              margin-top: 2px;
            "
          >
            Contact Us
          </p>
          <hr
            width="60px"
            color="black"
            style="margin-top: 20px; font-weight: 100"
          />
        </div>
      </div>
      <div
        style="
          flex: 3;
          height: 30vh;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 50px;
        "
      >
        <div
          style="
            width: 90%;
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
        >
          <div style="">
            <img
              style="position: absolute; margin-top: 10px"
              src="../assets/img/26.png"
              alt=""
              srcset=""
            />
            <p
              style="
                font-size: 20px;
                font-weight: 700;
                color: #505050;
                margin-top: 0px;
                margin-left: 50px;
              "
            >
              联系地址
            </p>
            <p
              style="
                font-size: 20px;
                font-weight: 400;
                color: #505050;
                margin-top: 2px;
                margin-left: 50px;
                width: 360px;
              "
            >
              河南省郑州市郑东新区龙子湖智慧岛中道东路创智天地大厦707室
            </p>
          </div>
          <div style="margin-top: 70px">
            <img
              style="position: absolute; margin-top: 10px"
              src="../assets/img/27.png"
              alt=""
              srcset=""
            />
            <p
              style="
                font-size: 20px;
                font-weight: 700;
                color: #505050;
                margin-top: 0px;
                margin-left: 50px;
              "
            >
              电子邮箱
            </p>
            <p
              style="
                font-size: 20px;
                font-weight: 400;
                color: #505050;
                margin-top: 2px;
                margin-left: 50px;
              "
            >
              excellent1405@163.com
            </p>
          </div>
        </div>
      </div>
      <div
        style="
          flex: 4;
          height: 40vh;
          margin-top: 60px;
          display: flex;
          justify-content: center;
        "
      >
        <div style="width: 90%; position: relative">
          <img style="" src="../assets/img/28.png" alt="" srcset="" />
          <span
            style="
              font-size: 40px;
              font-weight: 700;
              color: #019598;
              margin-left: 15px;
            "
            >0371-65011217</span
          >
          <div style="position: absolute; bottom: 130px">
            {{ `©${year}`
            }}<a
              style="text-decoration: none; color: #505050; margin-left: 5px"
              href="http://beian.miit.gov.cn/"
              target="_blank"
              >豫ICP备2024047107号-1</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapView",
  components: {},
  props: {},
  data() {
    return {
      year: new Date().getUTCFullYear(),
      //点数据数组

      markerArr: [
        {
          id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id

          styleId: "myStyle", //指定样式id

          position: new TMap.LatLng(34.79581440652456, 113.80814574215424), //点标记坐标位置

          properties: {
            //自定义属性

            title: "河南省埃科森道路新技术有限公司",
          },
        },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {
    initMap() {
      //设置一个默认的中心点 (荆州市)

      var center = new TMap.LatLng(34.79581440652456, 113.80814574215424);

      //初始化地图

      var map = new TMap.Map(document.getElementById("container"), {
        center: center, //设置地图中心点坐标

        zoom: 17, //设置地图缩放级别

        pitch: 60.5, //设置俯仰角
        scrollable: false, //禁止鼠标滚动

        // rotation: 45    //设置地图旋转角度
      });

      //  map.setCenter(new TMap.LatLng(this.lat, this.lng))
      //移除控件缩放

      // map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ZOOM);
      // 移除比例尺控件

      // map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.SCALE);

      // 移除旋转控件

      map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ROTATION);

      //地图加载完成事件
      map.on("tilesloaded", function () {});

      //设定marker点及其样式

      var markerLayer = new TMap.MultiMarker({
        map: map, //指定地图容器

        //样式定义

        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）

          myStyle: new TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）

            height: 35, // 点标记样式高度（像素）

            // "src": './images/R-C.png',  //图片路径

            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点

            // "anchor": { x: 16, y: 32 }  //marker点图片跟据焦点位置的偏移量
          }),
        },

        //点标记数据数组

        geometries: this.markerArr,
      });

      // 去除logo及水印
      //   var a = document.querySelector('canvas+div:last-child')
      //   a.style.display = 'none'

      //设置信息窗口

      var infoWindow = new TMap.InfoWindow({
        map: map,

        position: new TMap.LatLng(39.984104, 116.307503), //信息窗口默认位置
      });

      //  b.style.display="none"
      //信息窗口设置完后默认是打开的 可以先关闭初始信息窗口

      infoWindow.close();

      //监听marker点击事件

      markerLayer.on("click", function (evt) {
        const imgPath = require("../assets/img/28.png");
        console.log(imgPath);

        infoWindow.close(); //先关闭已打开信息窗口

        infoWindow.open(); //再打开 并赋给新的值

        infoWindow.setPosition(evt.geometry.position); //设置信息窗位置

        infoWindow.setContent(
          `<div><img class="img" style="width:250px" src="` +
            require("../assets/img/logogs.png") +
            `" alt=""><p>${evt.geometry.properties.title}</p></div>`
        ); //设置信息窗内容

        console.log(evt);
      });

      // 监听地图点击事件

      map.on("click", function (evt) {
        console.log(evt);
      });
    },
  },
  created() {},
  mounted() {
    this.initMap();
  },
};
</script>
<style lang="scss" scoped>
#box {
  width: 100vw;
  height: 100vh;
  background-color: #f9fafe;
}
#container {
  width: 100%;

  height: 100%;

  //  border: 1px solid red;
}

.img {
  width: 50px !important;
}
</style>