<template>
  <div id="box">
    <div class="aside">
      <div class="zx" style="width: 38%; height: 559px">
        <img
          width="100%"
          height="559px"
          src="../assets/img/xw1.png"
          alt=""
          srcset=""
        />
        <div class="zxTitle">
          <span
            style="
              font-size: 40px;
              color: #fff;
              margin-bottom: 2px;
              font-weight: 600;
            "
            >新闻资讯</span
          >
          <span style="font-size: 26px; color: #fff; margin-bottom: 20px"
            >News Consultation</span
          >
          <span style="width: 50px; height: 1px; background: #fff"></span>
        </div>
      </div>
      <div
        class="zx1"
        style="
          width: 57%;
          height: 559px;

          background: rgba(255, 255, 255, 0.81);
          display: flex;
          flex-direction: column;
        "
      >
        <div
          class="flex"
          style="flex: 3; margin-left: 20px"
          v-for="(item, index) in content"
          :key="item.id"
        >
          <div
            class="flex hover"
            style="width: 90%; height: 100%"
            @click="go(index)"
          >
            <div
              class="flex"
              style="
                flex: 1;
                flex-direction: column;
                border-bottom: 2px solid #2375a7;
              "
            >
              <p
                style="
                  font-size: 30px;
                  font-weight: 700;
                  color: #2375a7;
                  margin-top: 20px;
                "
              >
                {{ item.time }}
              </p>
              <p style="font-size: 20px; font-weight: 400; color: #808080">
                {{ item.times }}
              </p>
            </div>
            <div
              class="flex"
              style="
                flex: 8.5;
                flex-direction: column;
                border-bottom: 1px solid #d7d6d6;
                margin-top: 30px;
              "
            >
              <div
                class="content"
                style="
                  font-size: 24px;
                  font-weight: 700;
                  color: #383838;
                  margin-left: 50px;
                  transition: 0.3s;
                  width: 550px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
              >
                {{ item.title }}
              </div>
              <div
                class="box_1 content"
                style="
                  font-size: 18px;
                  font-weight: 200;
                  color: #808080;
                  margin-left: 50px;
                  line-height: 30px;
                  width: 70%;
                  margin-top: 10px;
                  transition: 0.3s;
                "
              >
                {{ item.cont }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex" style="flex: 1.5">
          <div
            style="
              width: 90%;
              align-items: center;
              justify-content: flex-end;
              display: flex;
              color: #2375a7;
              cursor: pointer;
            "
            @click="$router.push({ path: '/news', query: { id: 3 } })"
          >
            查看更多<img
              style="margin-left: 5px"
              src="../assets/img/right1.png"
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsView",
  components: {},
  props: {},
  data() {
    return {
      content: [
        {
          title:
            "省交通运输厅开展2023年度全省交通运输领域科技创新培训和标准宣贯工作",
          time: "2023",
          times: "12.16",
          cont: "12月5日至8日，省交通运输厅开展2023年度全省交通运输领域科技创新培训和标准宣贯，此次培训和标准宣贯是贯彻落实创新驱动发展战略...",
        },
        {
          title: "贯彻党的二十大精神 加快交通强省建设",
          time: "2023",
          times: "12.11",
          cont: "11月28日，中共河南省委宣传部举行“全面贯彻党的二十大精神奋力推进中国式现代化建设河南实践”第10场新闻发布会，介绍和解读我省交通...",
        },
        {
          title: "隧道智慧化升级探索实践及关键技术",
          time: "2023",
          times: "11.17",
          cont: "随着我国交通行业的迅速发展，隧道作为高速公路的关键构造物，如何利用信息技术提升隧道的运行效率、提高用户体验，成为关注重点...",
        },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {
    go(ind) {
      let ind_ = ind + 2;
      console.log(ind_);
      this.$router.push({ path: `/centers${ind_}`, query: { id: 3 } });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
#box {
  width: 100vw;
  height: 100vh;
  background: url(../assets/img/xw.png) no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  .aside {
    width: 90%;
    height: 631px;
    background: rgba(255, 255, 255, 0.4);
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.flex {
  display: flex;
  justify-content: center;
}
.box_1 {
  overflow: hidden;
  text-overflow: ellipsis;
  //   width: 200px;
  /* 将对象作为弹性伸缩盒子模型显示 */
  display: -webkit-box;
  /* 限制在一个块元素显示的文本的行数 */
  /* -webkit-line-clamp 其实是一个不规范属性，使用了WebKit的CSS扩展属性，该方法适用于WebKit浏览器及移动端；*/
  -webkit-line-clamp: 2;
  /* 设置或检索伸缩盒对象的子元素的排列方式 */
  -webkit-box-orient: vertical;
}
.hover:hover {
  cursor: pointer;
  // background-color: aqua;
  // scale: 1.01;
  .content {
    scale: 1.05;
  }
}

.zx {
  position: relative;

  .zxTitle {
    position: absolute;
    left: 40px;
    top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
</style>