<template>
  <div>
    <div
      style="
        width: 33px;
        height: 95px;
        z-index: 99 !important;
        position: absolute;
        bottom: 140px;
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      "
    >
      <img
        style="width: 25px; height: 35px"
        src="../assets/img/curs.png"
        alt=""
        srcset=""
      />
      <div
        style="
          width: 2px;
          height: 25px;
          background-color: rgba(236, 240, 243, 0.3);
          margin-top: 5px;
          overflow: hidden;
        "
      >
        <div class="innerBox"></div>
      </div>
      <span style="font-size: 14px; color: aliceblue; margin-top: 5px"
        >下 滑</span
      >
    </div>

    <div class="bto">
      <div class="interval" style="height: 80px">
        <img src="../assets/img/font.png" alt="" srcset="" />
        <div class="gl">
          <span class="tit">公司成立于2004年</span>
          <span class="cot">The company was founded in 2004</span>
        </div>
      </div>
      <div class="interval" style="height: 80px">
        <img src="../assets/img/yuan.png" alt="" srcset="" />
        <div class="gl">
          <span class="tit">业务涵盖全国20多省市</span>
          <span class="cot">The business covers more than 20 </span>
          <span class="cot">provinces and cities across the country</span>
        </div>
      </div>
      <div class="interval" style="height: 80px; border-right: none">
        <img src="../assets/img/hand-icon.png" alt="" srcset="" />
        <div class="gl">
          <span class="tit">全国合作单位20多家</span>
          <span class="cot">There are more than 20 cooperative</span>
          <span class="cot"> units nationwide</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.bto {
  position: absolute;
  bottom: 0px;
  height: 120px;
  opacity: 0.7;
  color: white;
  background: linear-gradient(
    90deg,
    rgba(1, 149, 153, 0.9) 0%,
    rgba(36, 117, 170, 0.9) 100%
  );
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.interval {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: 150px; */
  border-right: 1px solid white;
}
.cot {
  font-size: 13px;
  line-height: 16px;
  white-space: nowrap;
}

.tit {
  font-size: 18px;
  line-height: 40px;
  white-space: nowrap;
}
.gl {
  display: flex;
  flex-direction: column;
  margin-left: 25px;
}

.innerBox {
  width: 100%;
  height: 10px;
  background-color: #fff;
  animation: move 1.5s linear infinite;
}

@keyframes move {
  0% {
  }
  100% {
    transform: translateY(27px);
  }
}
</style>