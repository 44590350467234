<template>
  <div id="body_">
    <div class="index">
      <HelloWorld />
    </div>
    <div>
      <keep-alive>
        <router-view />
      </keep-alive>
    </div>
  </div>
</template>
<script>
import HelloWorld from "./components/HelloWorld.vue";
import request from "./utils/request";
export default {
  components: {
    HelloWorld,
  },
  props: {},
  data() {
    return {};
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
* {
  margin: 0;
  padding: 0;
}
::-webkit-scrollbar {
  display: none;
}
</style>
