import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
const routerPush = VueRouter.prototype.push;

VueRouter.prototype.push = function (location) {

  return routerPush.call(this, location).catch(err => { })

}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/busin',
    name: 'busin',
    component: () => import('../views/BusinessView.vue')
  }
  ,
  {
    path: '/green',
    name: 'green',
    component: () => import('../components/GreenEss.vue')
  },
  {
    path: '/smart',
    name: 'smart',
    component: () => import('../components/SmartView.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/NewsCenter.vue')
  }
  ,
  {
    path: '/call',
    name: 'call',
    component: () => import('../views/CallUsView.vue')
  },
  {
    path: '/pose',
    name: 'pose',
    component: () => import('../components/PurPose.vue')
  },
  {
    path: '/airp',
    name: 'airp',
    component: () => import('../components/airport.vue')
  },
  {
    path: '/engin',
    name: 'engin',
    component: () => import('../components/engineering.vue')
  },
  {
    path: '/bloss',
    name: 'bloss',
    component: () => import('../components/blossom.vue')
  },
  {
    path: '/berlin',
    name: 'berlin',
    component: () => import('../components/Berlin.vue')
  },
  {
    path: '/hbger',
    name: 'hbger',
    component: () => import('../components/Hamburg.vue')
  },
  {
    path: '/centers1/:typeCode/:getId',
    name: 'centers1',
    component: () => import('../components/Centers1.vue')
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
})


export default router
