<template>
  <div class="showBox">
    <div
      class="box"
      style="background: rgba(20, 126, 183, 0.9)"
      @mouseenter="enter"
      @mouseleave="enters"
      @click="to('/busin', 2)"
      :class="eleindex == 0 ? 'eleactive' : ''"
    >
      <div
        style="
          height: 30vh;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          color: aliceblue;
          margin-left: 80px;
          margin-top: 80px;
        "
      >
        <h1 style="white-space: nowrap; font-size: 40px; margin-bottom: 2px">
          业务简介
        </h1>
        <h2 style="white-space: nowrap; font-weight: 400; font-size: 26px">
          Business Profile
        </h2>
        <hr
          style="
            margin-top: 20px;
            width: 50px;
            color: rgba(255, 255, 255) !important;
          "
        />
      </div>
      <div
        style="
          height: 60vh;
          display: flex;
          flex-direction: column;
          overflow: hidden;
        "
      >
        <div style="height: 30vh; margin-left: 80px">
          <img
            style="margin-top: 60px"
            src="../assets/img/luj.png"
            alt=""
            srcset=""
          />
        </div>
        <div
          style="
            height: 65vh;
            color: rgb(254, 255, 255);
            font-size: 20px;
            font-weight: 100;
            margin-left: 80px;
          "
        >
          <div
            style="
              text-indent: 2em;
              width: 90%;
              line-height: 40px;
              font-size: 18px;
            "
          >
            历经二十年的科技创新与深度积累，我们的主营业务已经发展为绿色路面技术和智慧交通两大板块；我们的团队由一批经验丰富、技术精湛的工程师和科研人员组成，始终致力于推动交通行业的高质量发展和科技创新；我们以不断的创新和技术升级为动力，为客户提供更高效、更环保、更智能的解决方案，以实现交通行业的可持续发展，为客户创造更大的价值。
          </div>
          <div
            v-show="this.eleindex == 0"
            ref="hr"
            style="
              border-bottom: 0.5px solid rgba(255, 255, 255, 0.4);
              width: 90%;
              margin-top: 40px;
            "
          ></div>
        </div>
      </div>
      <div
        style="
          height: 15vh;
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          margin-right: 84px;
          color: aliceblue;
        "
      >
        <span style="margin-top: 20px" v-show="this.eleindex == 0"
          >查看更多<img
            width="30px"
            style="margin-left: 10px"
            src="../assets/img/right9.png"
            alt=""
            srcset=""
          />
        </span>
      </div>
    </div>
    <!-- 第二块 -->
    <div
      class="box"
      style="background: rgba(23, 127, 162, 0.7)"
      @mouseenter="enter1"
      @mouseleave="leva1"
      @click="to('/green', 2)"
      :class="eleindex == 1 ? 'eleactive' : ''"
    >
      <div
        style="
          height: 32vh;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          color: aliceblue;
          margin-left: 80px;
        "
      >
        <div style="margin-top: 0px">
          <img src="../assets/img/oo.png" alt="" srcset="" />
        </div>
        <h2 style="white-space: nowrap; margin-top: 10px">智慧交通</h2>
      </div>
      <!-- <Transition :duration="{ enter: 1500 ,leave:1000}" enter-active-class="animate__animated animate__backInUp" leave-active-class="animate__animated animate__backOutDown"> -->
      <div
        style="
          height: 58vh;
          display: flex;
          flex-direction: column;
          overflow: hidden !important;
        "
      >
        <div style="height: 30vh; margin-left: 80px">
          <!-- <img style="margin-top: 50px;" src="../assets/img/luj.png" alt="" srcset=""> -->
        </div>
        <div
          v-show="isSlid == 1"
          style="
            height: 60vh;
            color: aliceblue;
            font-size: 22px;
            font-weight: 100;
            margin-left: 80px;
          "
        >
          <div
            style="
              text-indent: 2em;
              width: 90%;
              line-height: 40px;
              font-size: 18px;
            "
          >
            致力于智慧交通、智慧城市等大模型、云控平台系统、多场景数字孪生系统软件开发、车-路-云-网-安全协同的智能交通体系等，涵盖了智慧城市、智慧高速、智慧公路、智慧服务区、智慧收费站、智慧桥隧、智慧园区等诸多应用场景，打造高效、安全、便捷的交通管理体系。
          </div>
          <!-- <div style="text-indent: 2em;width: 90%;line-height: 40px;margin-top: 20px;">我们的目标是通过不断的创新和技术升级，为客户提供更高效、更环保、更智能的解决方案，推动交通行业的可持续发展。</div> -->
          <div
            style="
              margin-top: 40px;
              width: 90%;
              border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            "
          ></div>
        </div>
      </div>
      <!-- </Transition> -->

      <div
        style="
          height: 15vh;
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          margin-right: 40px;
          color: aliceblue;
          width: 90%;
        "
      >
        <span style="margin-top: 20px; font-size: 22px">01 </span>
      </div>
    </div>
    <!-- 第三块 -->
    <div
      class="box"
      style="background: rgba(2, 147, 152, 0.7)"
      @mouseenter="enter2"
      @mouseleave="leva2"
      @click="to('/smart', 2)"
      :class="eleindex == 2 ? 'eleactive' : ''"
    >
      <div
        class="box"
        style="background: rgba(23, 127, 162, 0.7)"
        :class="eleindex == 1 ? 'eleactive' : ''"
      >
        <div
          style="
            height: 32vh;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            color: aliceblue;
            margin-left: 80px;
          "
        >
          <div style="margin-top: 0px">
            <img src="../assets/img/pp.png" alt="" srcset="" />
          </div>
          <h2 style="white-space: nowrap; margin-top: 10px">绿色路面</h2>
        </div>
        <!-- <Transition :duration="{ enter: 1500 ,leave:1000}" enter-active-class="animate__animated animate__backInUp" leave-active-class="animate__animated animate__backOutDown"> -->
        <div
          style="
            height: 58vh;
            display: flex;
            flex-direction: column;
            overflow: hidden !important;
          "
        >
          <div style="height: 30vh; margin-left: 80px">
            <!-- <img style="margin-top: 50px;" src="../assets/img/luj.png" alt="" srcset=""> -->
          </div>
          <div
            v-show="isSlid == 2"
            style="
              height: 60vh;
              color: aliceblue;
              font-size: 22px;
              font-weight: 100;
              margin-left: 80px;
            "
          >
            <div
              style="
                text-indent: 2em;
                width: 90%;
                line-height: 40px;
                font-size: 18px;
              "
            >
              在绿色路面技术领域，我们专注于研究和开发多种新型路面技术和材料，如绿色沥青路面、高性能长寿命路面、耐久型沥青路面等，这些技术具有节能、减排、环保等特性，能够显著降低道路建设与维护对环境产生的影响，致力于推动可持续交通发展。
            </div>
            <div
              style="
                margin-top: 40px;
                width: 90%;
                border-bottom: 1px solid rgba(255, 255, 255, 0.5);
              "
            ></div>
          </div>
        </div>

        <div
          style="
            height: 15vh;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            margin-right: 40px;
            color: aliceblue;
            width: 90%;
          "
        >
          <span style="margin-top: 20px; font-size: 22px !important">02 </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      isSlid: 0,
      eleindex: 0,
      index: 1,
    };
  },
  watch: {},
  computed: {},
  methods: {
    // enter: function (i, item) {
    //     this.eleindex = i;
    //     this.lisData = item;
    //     console.log(i, item, this.eleindex);
    // },
    enter() {
      this.eleindex = 0;
    },
    //移出第一个
    enters() {},
    enter1() {
      this.eleindex = 1;
      this.isSlid = 1;
    },
    leva1() {
      this.isSlid = 0;
      this.eleindex = 0;
    },
    leva2() {
      this.isSlid = 0;
      this.eleindex = 0;
    },
    enter2() {
      this.eleindex = 2;
      this.isSlid = 2;
    },
    ounds(ele) {
    },
    to(e, id) {
      this.$router.push({ path: e, query: { id: id } });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.showBox {
  width: 100%;
  height: 100vh;
  display: flex;
  background: url(../assets/img/mal.png) no-repeat;
  background-size: cover;
  flex: 1;
  transition: 0.5s;
  overflow: hidden;
}

.eleactive {
  flex: 1 1 22% !important;
  transition: 1.5s;
}

.eleactive:hover {
  cursor: pointer;
}

.box {
  height: 100%;
  flex: 1;
  overflow: hidden;
  transition: 1s;
  display: flex;
  flex-direction: column;
}
</style>