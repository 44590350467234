<template>
  <div
    class="title"
    style="
      display: flex;
      justify-content: space-around;
      border-bottom: 0.5px solid #d1d1d1;
    "
  >
    <div style="flex: 0.5"></div>
    <div
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 6.5;
      "
    >
      <img width="500px" src="../assets/img/LOGO.png" alt="" srcset="" />
    </div>

    <div style="color: black; align-items: center; display: flex; flex: 2">
      <ul style="display: flex; list-style: none; align-items: center">
        <li
          style="width: 95px"
          :class="{ active: index === number }"
          @click="to(index)"
          v-for="(item, index) in items"
        >
          {{ item.txt }}
        </li>
      </ul>
    </div>
    <div style="flex: 1"></div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {
    name: "HelloWorld",
  },
  props: {},
  data() {
    return {
      number: 0,
      items: [
        { txt: "首页" },
        { txt: "关于我们" },
        { txt: "业务板块" },
        { txt: "新闻中心" },
        { txt: "联系我们" },
      ],
    };
  },
  watch: {},
  computed: {},
  created() {
    this.number = this.$route.query;
  },
  methods: {
    to(e) {
      switch (e) {
        case 0:
          this.number = e;
          this.$router.push("/?id=0");
          break;
        case 1:
          this.number = e;
          this.$router.push("/about?id=1");
          break;
        case 2:
          this.number = e;
          this.$router.push("/busin?id=2");
          break;
        case 3:
          this.number = e;
          this.$router.push("/news?id=3");
          break;
        case 4:
          this.number = e;
          this.$router.push("/call?id=4");
          break;
      }
    },
  },
  created() {},
  mounted() {},
  watch: {
    $route(to) {
      this.number = Number(to.query.id);
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  height: 80px;
  color: white;
  background-color: white;
  display: flex;
  width: 100%;
  display: flex-end;
  position: fixed;
  z-index: 9;
}

ul > li {
  width: 90px;
  font-size: 22px;
  margin-left: 50px;
}

ul > li:hover {
  color: #2375a7;
  cursor: pointer;
}

.active {
  color: #2375a7;
}

li:nth-child(1) {
  display: flex;
  justify-content: space-around;
}
</style>