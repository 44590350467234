<template>
  <div style="margin: 0; padding: 0">
    <div class="swiper-container swiper-no-swiping">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img
            src="../assets/img//107.png"
            style="width: 100vw; height: 100vh"
            alt=""
            srcset=""
          />
          <HomeOne />
        </div>
        <div class="swiper-slide">
          <div class="cll">
            <Transition
              :duration="{ enter: 900 }"
              enter-active-class="animate__animated animate__fadeInLeft"
            >
              <div
                v-show="this.currentPage == '1' ? true : false"
                class="aside_1"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <div style="width: 80%; height: 76vh; margin-top: 80px">
                  <div
                    style="
                      width: 100%;
                      height: 20%;
                      color: white;
                      display: flex;
                      justify-content: space-evenly;
                      flex-direction: column;
                    "
                  >
                    <h1 style="font-size: 40px">公司简介</h1>
                    <h3
                      style="
                        margin-top: -20px;
                        font-size: 26px;
                        font-weight: 400;
                      "
                    >
                      Company Profile
                    </h3>
                    <hr style="width: 8%" />
                  </div>
                  <div
                    style="
                      width: 100%;
                      height: 60%;
                      font-size: 22px;
                      text-indent: 2em;
                      color: white;
                      line-height: 45px;
                      font-weight: 300;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    河南埃科森道路新技术有限公司（简称埃科森）位于郑州智慧岛创智天地，成立于2004年，集产、投、研、用为一体，长期专注于公路基建和交安机电新技术的研发和应用，主要包含绿色公路新材料的研究与应用，智慧交通集成服务以及融合5G通讯、云计算、数字赋能、车路协同的智慧交通云控平台、多场景应用软件的研究与开发，业务涵盖全国20多省市。
                  </div>
                  <div
                    style="
                      width: 100%;
                      height: 20%;
                      color: white;
                      margin-top: 30px;
                    "
                  >
                    <div class="bodr" style="" @click="gsgk()">查看更多</div>
                  </div>
                </div>
              </div>
            </Transition>
            <div class="aside_2">
              <Transition
                :duration="{ enter: 1500 }"
                enter-active-class="animate__animated animate__pulse"
              >
                <div
                  class="flex"
                  v-show="this.currentPage == '1' ? true : false"
                  style="width: 80%; height: 421px; margin-top: 50px"
                  @click="gsgk"
                >
                  <div
                    class="flex"
                    style="
                      flex: 5;
                      border: 1px solid white;
                      flex-direction: column;
                    "
                  >
                    <div class="flex yellow" style="flex: 5" @click="gsgk">
                      <div class="flex_center" style="flex: 4; height: 209px">
                        <img src="../assets/img/11.png" alt="" srcset="" />
                      </div>
                      <div
                        class="flex"
                        style="
                          flex: 6;
                          height: 209px;
                          flex-direction: column;
                          color: white;
                        "
                      >
                        <div
                          class="flex"
                          style="
                            flex: 5;
                            align-items: flex-end;
                            font-size: 26px;
                            white-space: nowrap;
                          "
                        >
                          公司概况
                        </div>
                        <br />
                        <div
                          style="flex: 5; font-size: 20px; white-space: nowrap"
                        >
                          Company Overview
                        </div>
                      </div>
                    </div>
                    <div
                      style="flex: 5; border-top: 1px solid white"
                      @click="qyzz('2')"
                    >
                      <div class="flex yellow" style="flex: 5">
                        <div class="flex_center" style="flex: 4; height: 209px">
                          <img src="../assets/img/333.png" alt="" srcset="" />
                        </div>
                        <div
                          class="flex"
                          style="
                            flex: 6;
                            height: 209px;
                            flex-direction: column;
                            color: white;
                          "
                        >
                          <div
                            class="flex"
                            style="
                              flex: 5;
                              align-items: flex-end;
                              font-size: 26px;
                              white-space: nowrap;
                            "
                          >
                            企业资质
                          </div>
                          <br />
                          <div
                            style="
                              flex: 5;
                              font-size: 20px;
                              white-space: nowrap;
                            "
                          >
                            Company Overview
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex"
                    style="
                      flex: 5;
                      border: 1px solid white;
                      flex-direction: column;
                    "
                  >
                    <div style="flex: 5" @click="qywh('1')">
                      <div class="flex yellow" style="flex: 5">
                        <div class="flex_center" style="flex: 4; height: 209px">
                          <img src="../assets/img/222.png" alt="" srcset="" />
                        </div>
                        <div
                          class="flex"
                          style="
                            flex: 6;
                            height: 209px;
                            flex-direction: column;
                            color: white;
                          "
                        >
                          <div
                            class="flex"
                            style="
                              flex: 5;
                              align-items: flex-end;
                              font-size: 26px;
                              white-space: nowrap;
                            "
                          >
                            企业文化
                          </div>
                          <br />
                          <div
                            style="
                              flex: 5;
                              font-size: 20px;
                              white-space: nowrap;
                            "
                          >
                            corporate culture
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="yellow"
                      style="
                        flex: 5;
                        border-top: 1px solid white;
                        color: aliceblue;
                        font-size: 26px;
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                      "
                      @click="gsgk"
                    >
                      <div
                        style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        更多详情
                      </div>
                      <div
                        style="
                          display: flex;
                          justify-content: space-around;
                          margin-top: 50px;
                        "
                      >
                        <img
                          style="width: 60px"
                          src="../assets/img/right9.png"
                          alt=""
                          srcset=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <AccorDion />
        </div>
        <div class="swiper-slide">
          <NewsView />
        </div>
        <div class="swiper-slide">
          <CooperaTive />
        </div>
        <div class="swiper-slide">
          <MapView />
        </div>
      </div>
    </div>
  </div>
</template>
   
   <script>
import AccorDion from "./AccorDion.vue";
import NewsView from "./NewsView.vue";
import CooperaTive from "./CooperaTive.vue";
import MapView from "./MapView.vue";
import HomeOne from "./HomeOne.vue";
import HomeTow from "./HomeTow.vue";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
export default {
  name: "home",
  components: {
    AccorDion,
    NewsView,
    CooperaTive,
    MapView,
    HomeOne,
    HomeTow,
  },
  data() {
    return {
      timeOut: null,
      currentPage: "", //当前页控制类名
    };
  },
  mounted() {
    let that = this;
    var swiper = new Swiper(".swiper-container", {
      direction: "vertical", // 垂直切换选项
      loop: false, // 循环模式选项
      loopPreventsSlide: true, // 当swiper 正在过渡时，阻止slide 前进后退的切换操作
      slidesPerView: 1, // swiper容器同时展示的元素数量
      speed: 300, // slider自动滑动开始到结束的时间
      mousewheel: true, //鼠标滑动切换页面
      on: {
        slideChangeTransitionStart: function () {
          that.currentPage = this.activeIndex;
        },
      },
    });
  },
  methods: {
    qywh(e) {
      console.log(e);
      this.$router.push({ path: "/about", query: { ids: e } });
    },
    qyzz(e) {
      console.log(e);
      this.$router.push({ path: "/about", query: { ids: e } });
    },
    gsgk() {
      this.$router.push({ path: "/about" });
    },
  },
};
</script>
   
   <style lang="scss" scoped>
.swiper-container {
  height: 100vh;
  width: 100%;
}
.box {
  background-color: #ccc;
  height: 100vh;
}
.el-carousel-item {
  width: 100%;
  height: 100%;
  background-color: skyblue;
}
:deep .el-carousel__container {
  height: 100vh !important;
}
* {
  margin: 0;
  padding: 0;
}

.bto {
  height: 150px;
  opacity: 0.7;
  color: white;
  background: linear-gradient(
    90deg,
    rgba(1, 149, 153, 0.9) 0%,
    rgba(36, 117, 170, 0.9) 100%
  );
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.cll {
  width: 100%;
  height: 100vh;
  background: url(../assets/img/8.png) no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: space-between;
}

.aside_1 {
  width: 45%;
  height: 100vh;
  background: linear-gradient(180deg, rgba(0, 150, 151, 0.94) 0%, #2476a8 100%)
    no-repeat center center;
  background-size: cover;
  opacity: 0.9;
}

.aside_2 {
  width: 55%;
  height: 100vh;
  /* background-color: aqua; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.center_left {
  width: 80%;
  height: 100vh;
  margin-top: 25%;
  /* border: 2px solid black; */
  color: white;
}
.bodr {
  border: 1px solid white;
  width: 130px;
  line-height: 40px;
  display: flex;
  justify-content: center;
}

.bodr:hover {
  cursor: pointer;
}

.h1 {
  font-size: 32px;
  font-weight: 500;
}

.v-enter-active {
  transition: all 5s ease;
}

.flex {
  display: flex;
}

.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.yellow:hover {
  background-color: #ffbf60;
  cursor: pointer;
}
@media screen and (max-height: 960px) {
}
</style>
   