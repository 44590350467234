<template>
  <div>
    <div class="swiper-container1 swiper-no-swiping">
          <div class="swiper-wrapper" ref="swiper">
            <div class="swiper-slide">
              <img class="aa" src="../assets/img/90.png" alt="" srcset="" />
            </div>
            <div class="swiper-slide">
              <img class="a1" src="../assets/img/91.png" alt="" srcset="" />
            </div>
            <div class="swiper-slide">
              <img class="a2" src="../assets/img/92.png" alt="" srcset="" />
            </div>
            <div class="swiper-slide">
              <img class="a3" src="../assets/img/93.png" alt="" srcset="" />
            </div>
            <div class="swiper-slide">
              <img class="a4" src="../assets/img/94.png" alt="" srcset="" />
            </div>
          </div>
        </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import 'swiper/css/swiper.min.css'
export default {
  components: {
  },
  props: {},
  data() {
    return {
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {
    
  },
  activated() {
    var swiper = new Swiper(".swiper-container1", {
      direction: "horizontal", // 垂直切换选项
      loop: true, // 循环模式选项
      loopPreventsSlide: true, // 当swiper 正在过渡时，阻止slide 前进后退的切换操作
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      slidesPerView: 5, // swiper容器同时展示的元素数量
      slidesPerGroup:5,
      spaceBetween: 0, // 元素之间间隔距离
      speed: 20000, // slider自动滑动开始到结束的时间
      autoplay: {
        // 开启自动切换
        delay: 0, // 自动切换的时间间隔
        stopOnLastSlide: false, // 当切换到最后一个slide时停止自动切换
        disableOnInteraction: false, // 用户操作swiper之后，是否停止自动切换效果
      },
    });
  },
};
</script>
<style lang="scss" scoped>
.swiper-container1 {
  width: 90%;
  height: 200px;
  overflow: hidden;
  display: flex;
  margin-left: 5%;
  min-width: 1400px;
}

.swiper-wrapper {
  padding: 10px;
  box-sizing: border-box;
}

.swiper-slide {
  border-radius: 20px;
  margin-bottom: 24px;
}

img {
  width: 80%;
}

.swiper-wrapper {
  transition-timing-function: linear !important;
  -webkit-transition-timing-function: linear !important;
  -moz-transition-timing-function: linear !important;
  -ms-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
}

.a1:hover {
  scale: 1.2;
}

.a2:hover {
  scale: 1.2;
}

.a3:hover {
  scale: 1.2;
}

.a4:hover {
  scale: 1.2;
}

.aa:hover {
  scale: 1.2;
}
</style>